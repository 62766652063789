











































import { Vue, Component, Prop } from 'vue-property-decorator';

import DocumentModel from '@/app/modules/documents/models/DocumentModel';

import Card from '@/shared/resources/components/cards/Card.vue';
import Detail from '@/shared/resources/components/details/Detail.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import DetailDivider from '@/shared/resources/components/details/DetailDivider.vue';

@Component({
  components: {
    DetailDivider,
    Divider,
    Detail,
    Card,
  },
})
export default class DocumentGeneralDetails extends Vue {
  @Prop() private document!: DocumentModel;
}
