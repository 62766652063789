






















import { Component, Prop, Vue } from 'vue-property-decorator';

import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';

@Component({
  components: {
    GridCol,
    GridRow,
  },
})
export default class Detail extends Vue {
  /**
   * Props
   */
  @Prop() private name!: string;
  @Prop() private value?: any;

  @Prop({ default: false, type: Boolean }) private bold!: boolean;
  @Prop({ default: false, type: Boolean }) private italic!: boolean;
  @Prop({ default: 4 }) private nameCols!: number;
  @Prop({ default: 8 }) private valueCols!: number;
  @Prop() private classNames?: string;
  @Prop() private valueClass?: object;

  /**
   * Getters
   */
  private get displayedValue(): string {
    if (typeof this.value === 'boolean') {
      return this.value
        ? this.$t('common.yes').toString()
        : this.$t('common.no').toString();
    }

    return this.value || '-';
  }

  /**
   * Display getters
   */
  private get displayValueSlot(): boolean {
    return !!this.$slots.default;
  }

  /**
   * Class names
   */
  private get valueClassNames(): object {
    let classNames: object = {
      'font-weight-bold': this.bold,
      'font-italic': this.italic,
    };

    if (this.valueClass) {
      classNames = {
        ...this.valueClass,
        ...classNames,
      };
    }

    return classNames;
  }
}
