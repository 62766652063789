
























import { Vue, Component, Prop } from 'vue-property-decorator';

import SaleInvoiceCoreModel from '@/modules/documents/models/SaleInvoiceCoreModel';
import DocumentGeneralDetails from './partials/details/DocumentGeneralDetails.vue';
import Loader from '@/shared/resources/components/Loader.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import DocumentItemsTable from '@/app/modules/documents/components/partials/tables/DocumentItemsTable.vue';
import DocumentTotalsDetails from '@/app/modules/documents/components/partials/details/DocumentTotalsDetails.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import SaleInvoiceModel from '@/app/modules/documents/models/SaleInvoiceModel';

@Component({
  components: {
    Card,
    DocumentTotalsDetails,
    DocumentItemsTable,
    GridCol,
    GridRow,
    Loader,
    DocumentGeneralDetails,
  },
})
export default class SaleInvoiceDetails extends Vue {
  /**
   * Props
   */
  @Prop() private id!: number;

  /**
   * Data
   */
  private saleInvoice: SaleInvoiceCoreModel | null = null;
  private loading: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Display getters
   */
  private get displayDetails(): boolean {
    return !this.loading && !!this.saleInvoice;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.getSaleInvoice();
  }

  /**
   * Methods
   */
  private async getSaleInvoice() {
    this.loading = true;

    try {
      this.saleInvoice = await SaleInvoiceModel
          .apiExt()
          .param('id', this.id)
          .with({
                documentItems: {
                  with: 'product',
                },
              },
          )
          .get();

      this.$emit('get', this.saleInvoice);
    } catch (ex) {
      this.errorMessage = ex.getMessages().join('/n');
    }

    this.loading = false;
  }
}
