




import { Vue, Component, Prop } from 'vue-property-decorator';
import Divider from '../Divider.vue';

@Component({
  components: {
    Divider,
  },
})
export default class DetailDivider extends Vue {
  /**
   * Props
   */
  @Prop({ default: 4 }) private margin!: number;
}
