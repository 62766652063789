





































import { Vue, Component, Prop } from 'vue-property-decorator';

import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import Divider from '@/shared/resources/components/Divider.vue';
import Detail from '@/shared/resources/components/details/Detail.vue';
import Card from '@/shared/resources/components/cards/Card.vue';

@Component({
  components: {
    Card,
    Detail,
    Divider,
  },
})
export default class DocumentTotalsDetails extends Vue {
  /**
   * Props
   */
  @Prop() private document!: DocumentModel;

  /**
   * Getters
   */
  private get status(): string {
    return this.$t('documents.statuses.' + this.document.status).toString();
  }

  /**
   * Display getters
   */
  private get displayPaymentDate(): boolean {
    return this.document.isOutdated;
  }

  /**
   * Class names
   */
  private get totalClassNames(): object {
    return {
      'text-h4 font-weight-bold mb-0': true,
      'accent--text': this.document.isCanceled,
      'success--text text--darken-1': this.document.isPaid,
      'error--text': this.document.isOutdated,
    };
  }

  private get subTotalClassNames(): object {
    return {
      'text-h6 mb-0': true,
      'accent--text': !this.document.isOutdated,
      'error--text text--lighten-1': this.document.isOutdated,
    };
  }

  private get statusClassNames(): object {
    return {
      'accent--text': this.document.isCanceled,
      'success--text text--darken-2': this.document.isPaid,
      'error--text font-weight-bold text-uppercase': this.document.isOutdated,
    };
  }

  private get paymentDateClassNames(): object {
    return {
      'accent--text': this.document.isCanceled,
      'error--text font-weight-bold': this.document.isOutdated,
    };
  }
}
